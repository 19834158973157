import leverApi from '@/lib/lever-dev';
import * as Sentry from '@sentry/nextjs';
import QueryString from 'qs';

import cmsClient from '@/lib/cms-client';

export const slugifyDepartment = (name) => {
  if (!name) return '';

  return encodeURIComponent(name.replaceAll(' ', '-')).toLowerCase();
};

export const unslugifyDepartment = (slug) => {
  if (!slug) return '';

  return decodeURIComponent(slug).toLowerCase().replaceAll('-', ' ');
};

export async function getCareers() {
  const query = {
    populate: [
      'seo',
      'seo.image',
      'joinUs',
      'joinUs.cards',
      'joinUs.spontaneousApplication',
      'joinUs.spontaneousApplication.footer',
      'joinUs.cta',
      'joinUs.cta.primaryHref',
      'joinUs.cta.secondaryHref',
      'mission',
      'mission.quotes',
      'mission.quotes.image',
      'investors',
      'investors.companies',
      'investors.companies.image',
      'values',
      'values.cards',
      'values.cards.image',
      'awards',
      'awards.awardsCard',
      'awards.awardsCard.image',
      'awards.awardsCard.imageHover',
      'header',
      'culture',
      'culture.cultures',
      'culture.cultures.image',
      'press',
      'press.pressCard',
      'press.pressCard.icon',
      'press.pressCard.logo',
      'press.pressCard.link',
      'press.pressCard.imageBackground',
      'news',
      'news.textLink',
      'news.articles',
      'news.articles.category',
      'news.articles.featuredImage',
      'news.articles.footnotes',
      'news.articles.footnotes.footnote',
      'video',
      'video.video',
      'video.previewVideo',
      'headerBanner',
      'headerBanner.logo',
    ],
  };
  const data = await cmsClient(
    `/career?${QueryString.stringify(query)}`,
    {
      next: { revalidate: 120 },
    },
    true,
  );

  return { ...data.data.attributes };
}

export async function getCareersJoinUs() {
  const query = {
    populate: [
      'seo.image',
      'joinUs.cards',
      'joinUs.spontaneousApplication',
      'joinUs.openJobs',
      'joinUs.cta',
      'joinUs.cta.primaryHref',
      'joinUs.cta.secondaryHref',
    ],
  };
  const data = await cmsClient(
    `/career?${QueryString.stringify(query)}`,
    {
      next: { revalidate: 120 },
    },
    true,
  );

  return { ...data.data.attributes };
}

export async function getLeverPostings() {
  try {
    const query = {
      state: 'published',
      distributionChannels: 'public',
      confidentiality: 'non-confidential',
    };
    const response = await leverApi(`/postings?${QueryString.stringify(query)}`);

    return response?.data || [];
  } catch (err) {
    Sentry.captureException(new Error('Lever Get Postings Error', { error: err }));

    return [];
  }
}

export async function getLeverDepartments() {
  try {
    const postings = await getLeverPostings();

    let departments = [];

    for (const posting of postings) {
      const department = posting.categories.department;
      const index = departments.findIndex((i) => i.name === department);

      if (index <= -1 && department) {
        departments.push({ name: department, quantify: 1 });
      } else {
        departments[index].quantify = departments[index].quantify + 1;
      }
    }

    return departments.sort((a, b) => a.name.localeCompare(b.name));
  } catch (err) {
    Sentry.captureException(new Error('Lever Get Departments Error', { error: err }));

    return [];
  }
}
export async function getAllPostingsByDepartment(slug) {
  try {
    let departments = [];
    const departmentName = unslugifyDepartment(slug).toLowerCase();
    const postings = await getLeverPostings();
    const data = postings.filter(
      (item) => item.categories.department.toLowerCase() === departmentName,
    );

    for (const posting of data) {
      const team = posting.categories.team;
      const index = departments.findIndex((i) => i.name === team);

      // Hide non public postings
      if (!posting.distributionChannels?.includes('public')) {
        continue;
      }

      if (index <= -1 && team) {
        departments.push({
          name: team,
          quantify: 1,
          list: [posting],
        });
      } else {
        departments[index].quantify = departments[index].quantify + 1;
        departments[index].list = [...departments[index].list, posting];
      }
    }

    return {
      title: data?.[0]?.categories.department || departmentName,
      postings: departments.sort((a, b) => a.name.localeCompare(b.name)),
    };
  } catch (err) {
    Sentry.captureException(new Error('Lever Get Postings by Department Error', { error: err }));

    // return null to prevent whole page from erroring out
    return null;
  }
}

export async function getLeverPosting(id, department = null, hideNonPublic = true) {
  try {
    if (!id) return null;

    const response = await leverApi(`/postings/${id}?distribution=external`);
    const data = response?.data;

    if (!data) {
      // return null to prevent whole page from erroring out
      return null;
    }

    // Hide closed postings
    if (data?.state === 'closed') {
      return null;
    }

    // Hide non public postings
    if (hideNonPublic && !data.distributionChannels?.includes('public')) {
      return null;
    }

    if (department && slugifyDepartment(data.categories?.department) !== department) {
      return null;
    }

    return data;
  } catch (err) {
    Sentry.captureException(new Error('Lever Get Posting Error', { error: err }));

    // return null to prevent whole page from erroring out
    return null;
  }
}

export async function getLeverPostingForm(id) {
  try {
    if (!id) return null;

    const response = await leverApi(`/postings/${id}/apply?distribution=external`);
    const data = response?.data || null;

    return data;
  } catch (err) {
    Sentry.captureException(new Error('Lever Get Form Error', { error: err }));

    // return null to prevent whole page from erroring out
    return null;
  }
}

export async function getLeverPostingDiversitySurvey(id, countryCode) {
  try {
    if (!id || !countryCode) return null;

    const response = await leverApi(`/surveys/diversity/${id}?country_code=${countryCode}`);
    const data = response?.data || null;

    return data;
  } catch (err) {
    Sentry.captureException(new Error('Lever Get Diversity Form Error', { error: err }));

    // return null to prevent whole page from erroring out
    return null;
  }
}

export function mapApplicationPayload(values, resumeData, diversitySurvey) {
  if (!values || !resumeData) return null;

  const eeoResponses = Object.keys(values.eeoResponses || {}).reduce((acc, key) => {
    const value = values.eeoResponses[key];

    if (value) {
      acc[key] = value;
    }

    return acc;
  }, {});
  const diversityResponses = Object.keys(values?.diversitySurvey?.responses || {}).reduce(
    (acc, key) => {
      const value = values.diversitySurvey.responses[key];

      if (value) {
        const question = diversitySurvey?.fields?.find((field) => field.id === key);
        acc.push({
          questionId: key,
          answer: value,
          questionText: question?.text,
          questionType: question?.type,
        });
      }

      return acc;
    },
    [],
  );

  // format data to match Lever API
  // https://hire.lever.co/developer/documentation#apply-to-a-posting
  return {
    id: values.id,
    customQuestions: Object.keys(values.customQuestions || {}).reduce((acc, key) => {
      acc.push({
        id: key,
        fields: values.customQuestions[key].map((value) => ({
          value: value ? value : null,
        })),
      });

      return acc;
    }, []),
    personalInformation: Object.keys(values.personalInformation || {}).reduce((acc, key) => {
      const value = values.personalInformation[key];
      const isResume = key === 'resume';

      if (value || isResume) {
        acc.push({
          name: key,
          value: isResume ? resumeData.uri : value,
        });
      }

      return acc;
    }, []),
    urls: Object.keys(values.urls || {}).reduce((acc, key) => {
      acc.push({
        name: key,
        value: values.urls?.[key] || '',
      });

      return acc;
    }, []),
    ...(Object.keys(eeoResponses).length > 0 ? { eeoResponses } : {}),
    ...(Object.keys(diversityResponses).length > 0
      ? {
          diversitySurvey: {
            ...values.diversitySurvey,
            surveyId: diversitySurvey.id,
            responses: diversityResponses,
          },
        }
      : {}),
  };
}

export async function applyLeverPosting(postingId, applicationData) {
  try {
    if (!postingId || !applicationData) {
      throw new Error('Missing application data');
    }

    const response = await leverApi(`/postings/${postingId}/apply?send_confirmation_email=true`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(applicationData),
    });
    const data = response?.data;

    if (!data) {
      throw new Error('Undefined response');
    }

    return data;
  } catch (err) {
    Sentry.captureException(new Error('Lever Application Error', { error: err }));

    throw new Error(`Failed Lever Request: ${JSON.stringify(err)}`);
  }
}

export async function uploadLeverFile(formData) {
  try {
    if (!formData) {
      throw new Error('Missing data');
    }

    const response = await leverApi('/uploads', {
      method: 'POST',
      body: formData,
    });

    const data = response?.data;

    if (!data) {
      throw new Error('Undefined file response');
    }

    return data;
  } catch (err) {
    Sentry.captureException(new Error('Lever Upload Error', { error: err }));

    throw new Error(`Failed Lever Request: ${err}`);
  }
}

export const getLogo = async (url) => {
  try {
    const response = await fetch('/api/read-file', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fileUrl: url }),
    })
      .then((res) => res.text())
      .then((svg) => svg);

    return `${response}`;
  } catch (err) {
    console.error('getLogo', err);
    return '';
  }
};
