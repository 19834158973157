import Image from 'next/image';
import { Paragraph, Title } from '@/components/core';

import { Wrapper, Figure, Content } from './style';
import { Button } from '@/components/core/Button';

const Empty = ({ size = 'md', title = 'Nothing to see here!', department, description, image }) => {
  return (
    <Wrapper $size={size}>
      <Figure>
        <Image
          src={image ? image : '/assets/careers/empty-box.svg'}
          alt=""
          width={80}
          height={80}
        />
      </Figure>
      <Content>
        <Title as="h4" className="title" size="2xl">
          {title}
        </Title>
        <Paragraph as="p" className="text" markdownProps={{ elements: ['span'] }}>
          {description
            ? description
            : `We don't have any open **${department}** roles at the moment. Feel free to check back soon, or apply for another open role.`}
        </Paragraph>
      </Content>
      <Button href="/company/careers#join-us-section" variant="secondary">
        Back to Careers
      </Button>
    </Wrapper>
  );
};

export default Empty;
